import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";
// import moment from "moment";
// import "moment/locale/es";
import { getProductPrice } from "../helpers/helper";
import { formatter } from "../../helpers/helper";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  greenText: {
    color: "#198754",
  },

  redText: {
    color: "#CC0000",
  },

  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    padding: "4px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "15%",
    fontSize: "10",
    lineHeight: 1.4,
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
    border: "1px solid #E9682A",
    fontFamily: "Helvetica-Bold",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function CostPage(props) {
  const {
    quote,
    //lang
  } = props;

  // const generateExpiryDate = () => {
  //   let localLocale = moment().add(30, "days");
  //   localLocale.locale("es");

  //   return localLocale.format("DD [de] MMMM [de] YYYY");
  // };

  // const generateDiscountHTML = () => {
  //   if (generateDiscount() > 0) {
  //     return (
  //       <View
  //         style={[
  //           {
  //             display: "flex",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //             alignItems: "center",
  //           },
  //         ]}

  //       >
  //         <Text
  //           style={[
  //             {
  //               fontSize: "11px",
  //             },
  //             styles.grayText,
  //           ]}
  //         >
  //           Descuento:
  //         </Text>
  //         <Text
  //           style={[
  //             {
  //               fontSize: "11px",
  //             },
  //             styles.bold,
  //             styles.upperCase,
  //             styles.grayText,
  //           ]}
  //         >
  //           -{formatter.format(parseInt(quote.discount, 10) / 100)}
  //         </Text>
  //       </View>
  //     );
  //   }
  // };

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount) / 100;
    }

    return 0;
  };

  const generateProductsPrice = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        return (sumPrice +=
          getProductPrice(qp.product, qp.quantity, quote.quote_products) *
          parseInt(qp.quantity, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  const generateFinalPrice = () => {
    let finalPrice = 0;
    if (quote.quote_products) {
      finalPrice += generateProductsPrice() - generateDiscount();
    }

    if (quote.allowance && quote.allowance > 0) {
      finalPrice += quote.allowance / 100;
    }

    return finalPrice;
  };

  // const generateProductsPriceHtml = () => {
  //   return (
  //     <View
  //       style={[
  //         {
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           paddingBottom: 24,
  //         },
  //       ]}
  //     >
  //       <Text
  //         style={[
  //           {
  //             fontSize: "11px",
  //           },
  //           styles.grayText,
  //         ]}
  //       >
  //         Subtotal
  //       </Text>
  //       <Text
  //         style={[
  //           {
  //             fontSize: "11px",
  //           },
  //           styles.bold,
  //           styles.upperCase,
  //           styles.blueText,
  //         ]}
  //       >
  //         {formatter.format(generateProductsPrice())}
  //       </Text>
  //     </View>
  //   );
  // };

  // const generateFinalPriceHtml = () => {
  //   return (
  //     <View
  //       style={[
  //         {
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //           paddingBottom: 24,
  //           paddingTop: 12,
  //         },
  //       ]}
  //     >
  //       <Text
  //         style={[
  //           {
  //             fontSize: "11px",
  //           },
  //           styles.grayText,
  //         ]}
  //       >
  //         TOTAL
  //       </Text>
  //       <Text
  //         style={[
  //           {
  //             fontSize: "20px",
  //           },
  //           styles.bold,
  //           styles.upperCase,
  //           styles.greenText,
  //         ]}
  //       >
  //         {formatter.format(generateFinalPrice())}
  //       </Text>
  //     </View>
  //   );
  // };

  const generateRoiByYear = () => {
    let roiRows = [];

    let price = generateFinalPrice();

    let years =
      Math.round((price / (quote.luma_monthly_bill / 100) / 12) * 10) / 10;

    let costoRestante = generateFinalPrice();

    let anual = (quote.luma_monthly_bill / 100) * 12;

    for (let i = 0; i <= years + 1 && i <= 25; i++) {
      roiRows.push(
        <View style={[styles.tableRow]} key={i + ""}>
          <Text style={[styles.cell]}>{i}</Text>
          <Text style={[styles.cell]}>{formatter.format(anual * i)}</Text>
          <Text
            style={[
              styles.cell,
              costoRestante * -1 < 0 ? styles.redText : styles.greenText,
            ]}
          >
            {formatter.format(costoRestante * -1)}
          </Text>
        </View>
      );

      costoRestante -= anual;
    }

    return roiRows;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>
        {/* Page Content */}


        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
           {/* Page Title */}
           <View
              style={[
                {
                  fontSize: "24px",
                  marginBottom: 24,
                  textAlign: "left",
                },
                styles.bold,
                styles.upperCase,
                styles.grayText,
              ]}
            >
              <Text>Retorno de Inversión (roi)  - 25 años</Text>
            </View>
            

            {/* ROI Section */}
            <View
              style={{
                width: "100%",
                display: "flex",
              }}
            >

              {/* ROI Table */}
              <View style={[styles.table]}>
                <View style={[styles.tableRow]}>
                  <Text
                    style={[
                      styles.cell,
                      styles.headerCell,
                      styles.upperCase,
                      styles.bold,
                    ]}
                  >
                    Año
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.headerCell,
                      styles.upperCase,
                      styles.bold,
                    ]}
                  >
                    Ahorro Anual
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.headerCell,
                      styles.upperCase,
                      styles.bold,
                    ]}
                  >
                    ROI
                  </Text>
                </View>
                {generateRoiByYear()}
              </View>
            </View>
          </View>
          <View />

         
        </View>
      </View>
    </Page>
  );
}

export default CostPage;
