import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton, Form } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import FilterRow from "./FilterRow";
import SubcategoriesList from "./SubcategoriesList";
import { connect } from "react-redux";
import { fetchSubcategoriesAction } from "../../redux/actions/subcategoryActions";
import SubcategoryFormModal from "../../components/modals/SubcategoryFormModal";
import EnableSubcategoryModal from "../../components/modals/EnableSubcategoryModal";

const SubcategoriesContainer = (props) => {
  const { subcategories, fetchSubcategories, loading } = props;

  useEffect(() => {
    if (!subcategories || (subcategories && subcategories.length === 0)) {
      fetchSubcategories();
    }
    // eslint-disable-next-line
  }, []);

  const [filteredSubcategories, setFilteredSubcategories] = useState(
    subcategories ? [...subcategories] : []
  );

  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState(false);
  const [openEnableModal, setOpenEnableModal] = useState(false);
  const [currentSubcategory, setCurrentSubcategory] = useState(null);

  const handleClick = () => {
    setCurrentSubcategory({});
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Subcategories List"
        buttons={[
          <div key="6" className="page-header-actions">
            <Button size="small" type="primary" onClick={handleClick}>
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          {loading && (
            <Col sm={24} xs={24}>
              <Cards headless>
                <Skeleton paragraph={{ rows: 20 }} active />
              </Cards>
            </Col>
          )}
          {!loading && (
            <>
              <Col sm={24} xs={24}>
                <FilterRow
                  setFilteredSubcategories={setFilteredSubcategories}
                  subcategories={subcategories}
                  form={form}
                />
              </Col>
              <Col sm={24} xs={24}>
                <SubcategoriesList
                  subcategories={filteredSubcategories ? [...filteredSubcategories] : []}
                  setOpen={setOpenModal}
                  setOpenEnableModal={setOpenEnableModal}
                  setCurrentSubcategory={setCurrentSubcategory}
                />
              </Col>
            </>
          )}
        </Row>
        <SubcategoryFormModal
          subcategory={currentSubcategory}
          open={openModal}
          setOpen={setOpenModal}
        />
        <EnableSubcategoryModal
          subcategory={currentSubcategory}
          open={openEnableModal}
          setOpen={setOpenEnableModal}
        />
      </Main>
    </>
  );
};

let mapStateToProps = (state) => ({
  subcategories: state.subcategories.subcategories,
  loading: state.subcategories.loading,
});

let mapDispatchToProps = (dispatch) => ({
  fetchSubcategories: () => dispatch(fetchSubcategoriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubcategoriesContainer);
