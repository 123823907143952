import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Skeleton, Form } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import FilterRow from "./FilterRow";
import UsersList from "./UsersList";
import { connect } from "react-redux";
import { fetchUsersAction } from "../../redux/actions/userActions";
import UserFormModal from "../../components/modals/UserFormModal";
import EnableUserModal from "../../components/modals/EnableUserModal";

const UsersContainer = (props) => {
  const { users, fetchUsers, loading, authUser } = props;

  const fetchUsersCallback = useCallback(() => {
    if (!users || (users && users.length === 0)) {
      fetchUsers();
    }
  }, [fetchUsers, users]);

  useEffect(() => {
    fetchUsersCallback();
  }, [fetchUsersCallback]);

  const [form] = Form.useForm();

  const [filteredUsers, setFilteredUsers] = useState(users ? [...users] : []);
  const [openModal, setOpenModal] = useState(false);
  const [openEnableModal, setOpenEnableModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  return (
    <>
      <PageHeader title="Users List" />
      <Main>
        <Row gutter={25}>
          {loading && (
            <Col sm={24} xs={24}>
              <Cards headless>
                <Skeleton paragraph={{ rows: 20 }} active />
              </Cards>
            </Col>
          )}
          {!loading && (
            <>
              <Col sm={24} xs={24}>
                <FilterRow
                  setFilteredUsers={setFilteredUsers}
                  users={users}
                  form={form}
                />
              </Col>
              <Col sm={24} xs={24}>
                <UsersList
                  users={filteredUsers ? filteredUsers : []}
                  setOpen={setOpenModal}
                  setOpenEnableModal={setOpenEnableModal}
                  setCurrentUser={setCurrentUser}
                  authUser={authUser}
                />
              </Col>
            </>
          )}
        </Row>
        <UserFormModal
          user={currentUser}
          open={openModal}
          setOpen={setOpenModal}
          authUser={authUser}
        />
        <EnableUserModal
          user={currentUser}
          open={openEnableModal}
          setOpen={setOpenEnableModal}
        />
      </Main>
    </>
  );
};

let mapStateToProps = (state) => ({
  users: state.users.users,
  loading: state.users.loading,
  authUser: state.auth.currentUser,
});

let mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsersAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
