import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import { getProductPrice } from "../helpers/helper";
import { hasSolarPanels } from "../helpers/helper";
import text from "./lang";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  greenText: {
    color: "#198754",
  },

  redText: {
    color: "#CC0000",
  },

  lightGrayBackground: {
    backgroundColor: "#F2F2F2",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "8",
    paddingBottom: "6",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
  },
  cell100: {
    paddingTop: "8",
    paddingBottom: "6",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell20: {
    paddingTop: "8",
    paddingBottom: "6",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "20%",
    fontSize: "10",
    lineHeight: 1.4,
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  headerCell: {
    backgroundColor: "#2c4596",
    color: "#FFFFFF",
    border: "1px solid #2c4596",
    fontFamily: "Helvetica-Bold",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function QuotePage(props) {
  const { lang, quote } = props;

  const generateProducts = () => {
    let productRows = [];

    productRows = [
      ...quote.quote_products.map((qp, index) => {
        return (
          <View
            style={[
              styles.tableRow,
              { backgroundColor: index % 2 === 1 ? "#F2F2F2" : "#FFFFFF" },
            ]}
            key={qp.id}
          >
            <Text style={[styles.cell20]}>{qp.quantity}</Text>
            <Text style={[styles.cell]}>{qp.product.name}</Text>
            <Text style={[styles.cell]}>
              {lang === "en" && qp.product.english_description
                ? qp.product.english_description
                : qp.product.description}
            </Text>
            <Text style={[styles.cell]}>
              {getProductPrice(qp.product, qp.quantity, quote.quote_products) >
              0
                ? formatter.format(
                    (getProductPrice(
                      qp.product,
                      qp.quantity,
                      quote.quote_products
                    ) *
                      parseInt(qp.quantity, 10)) /
                      100
                  )
                : text[lang].includedPrice}
            </Text>
          </View>
        );
      }),
    ];

    if (hasSolarPanels(quote)) {
      productRows.push(
        <View
          style={[
            styles.tableRow,
            {
              backgroundColor:
                productRows.length % 2 === 1 ? "#F2F2F2" : "#FFFFFF",
            },
          ]}
        >
          <Text style={[styles.cell20]}></Text>
          <Text style={[styles.cell]}>Unirac</Text>
          <Text style={[styles.cell]}>{text[lang].uniracDescription} </Text>
          <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
        </View>
      );
    }

    // Push additional rows that are always included
    productRows.push(
      <View
        style={[
          styles.tableRow,
          {
            backgroundColor:
              productRows.length % 2 === 1 ? "#F2F2F2" : "#FFFFFF",
          },
        ]}
      >
        <Text style={[styles.cell20]}></Text>
        <Text style={[styles.cell]}>Componentes Eléctricos</Text>
        <Text style={[styles.cell]}>
          Todo equipo y componentes eléctricos requeridos estan considerados
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    productRows.push(
      <View
        style={[
          styles.tableRow,
          {
            backgroundColor:
              productRows.length % 2 === 1 ? "#F2F2F2" : "#FFFFFF",
          },
        ]}
      >
        <Text style={[styles.cell20]}></Text>
        <Text style={[styles.cell]}>Monitoreo Remoto</Text>
        <Text style={[styles.cell]}>
          Acceso Web y APP para monitoreo de Sistema propuesto
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    productRows.push(
      <View
        style={[
          styles.tableRow,
          {
            backgroundColor:
              productRows.length % 2 === 1 ? "#F2F2F2" : "#FFFFFF",
          },
        ]}
      >
        <Text style={[styles.cell20]}></Text>
        <Text style={[styles.cell]}>Permisología</Text>
        <Text style={[styles.cell]}>
          Toda permisología relacionada a PREPA, esta incluída en nuestra
          propuesta. Cualquier permiso adicional que se requiera se considerará
          antes de proveer propuesta.
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    productRows.push(
      <View
        style={[
          styles.tableRow,
          {
            backgroundColor:
              productRows.length % 2 === 1 ? "#F2F2F2" : "#FFFFFF",
          },
        ]}
      >
        <Text style={[styles.cell20]}></Text>
        <Text style={[styles.cell]}>Operación y Mantenimiento</Text>
        <Text style={[styles.cell]}>
          Operación y/o mantenimiento de ser necesario se coordinará con
          encargado de localidad para inspección y/o mantenimiento
        </Text>
        <Text style={[styles.cell]}>{text[lang].includedPrice}</Text>
      </View>
    );

    if (quote.allowance && quote.allowance > 0) {
      productRows.push(
        <View
          style={[
            styles.tableRow,
            {
              backgroundColor:
                productRows.length % 2 === 1 ? "#F2F2F2" : "#FFFFFF",
            },
          ]}
        >
          <Text style={[styles.cell20]}></Text>
          <Text style={[styles.cell]}>Allowance</Text>
          <Text style={[styles.cell]}></Text>
          <Text style={[styles.cell]}>
            {formatter.format(quote.allowance / 100)}
          </Text>
        </View>
      );
    }

    return productRows;
  };
  const generateExpiryDate = () => {
    let localLocale = moment().add(30, "days");
    localLocale.locale("es");

    return localLocale.format("DD [de] MMMM [de] YYYY");
  };

  const generateDiscountHTML = () => {
    if (generateDiscount() > 0) {
      return (
        <View
          style={[
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            },
          ]}
        >
          <Text
            style={[
              {
                fontSize: "11px",
              },
              styles.grayText,
            ]}
          >
            Descuento:
          </Text>
          <Text
            style={[
              {
                fontSize: "11px",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            -{formatter.format(parseInt(quote.discount, 10) / 100)}
          </Text>
        </View>
      );
    }
  };

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount) / 100;
    }

    return 0;
  };

  const generateProductsPrice = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        return (sumPrice +=
          getProductPrice(qp.product, qp.quantity, quote.quote_products) *
          parseInt(qp.quantity, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  const generateFinalPrice = () => {
    let finalPrice = 0;
    if (quote.quote_products) {
      finalPrice += generateProductsPrice() - generateDiscount();
    }

    if (quote.allowance && quote.allowance > 0) {
      finalPrice += quote.allowance / 100;
    }

    return finalPrice;
  };

  const generateProductsPriceHtml = () => {
    return (
      <View
        style={[
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 12,
          },
        ]}
      >
        <Text
          style={[
            {
              fontSize: "11px",
            },
            styles.grayText,
          ]}
        >
          Subtotal
        </Text>
        <Text
          style={[
            {
              fontSize: "11px",
            },
            styles.bold,
            styles.upperCase,
            styles.blueText,
          ]}
        >
          {formatter.format(generateProductsPrice())}
        </Text>
      </View>
    );
  };

  const generateFinalPriceHtml = () => {
    return (
      <View
        style={[
          {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 12,
            paddingTop: "8px",
          },
        ]}
      >
        <Text
          style={[
            {
              fontSize: "11px",
            },
            styles.grayText,
          ]}
        >
          TOTAL
        </Text>
        <Text
          style={[
            {
              fontSize: "20px",
            },
            styles.bold,
            styles.upperCase,
            styles.greenText,
          ]}
        >
          {formatter.format(generateFinalPrice())}
        </Text>
      </View>
    );
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <View>
            {/* Page Title */}
            <View
              style={[
                {
                  fontSize: "24px",
                  marginBottom: 24,
                  textAlign: "left",
                },
                styles.bold,
                styles.upperCase,
                styles.grayText,
              ]}
            >
              <Text>EQUIPO PROPUESTO Y/O SERVICIOS</Text>
              <Text>INCLUIDOS</Text>
            </View>

            {/* Table */}
            <View style={[styles.table]}>
              <View style={[styles.tableRow]}>
                <Text
                  style={[
                    styles.cell20,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productQuantityHeader}
                  Cantidad
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productProductHeader}
                  Componente
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productDescriptionHeader}
                  Descripción
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.headerCell,
                    styles.upperCase,
                    styles.bold,
                  ]}
                >
                  {text[lang].productPriceHeader}
                  Costo
                </Text>
              </View>
              {generateProducts()}
            </View>
          </View>
          {/* COSTO DEL PROYECTO */}
          <View>
            <View
              style={[
                {
                  fontSize: "24px",
                  marginBottom: 24,
                  textAlign: "left",
                },
                styles.bold,
                styles.upperCase,
                styles.grayText,
              ]}
            >
              <Text>COSTO TOTAL DEL PROYECTO</Text>
            </View>
            {generateDiscount() > 0 && (
              <View
                style={{
                  paddingBottom: 24,
                  borderBottom: "1px solid light-gray",
                }}
              >
                {/* SubTotal */}
                {generateProductsPriceHtml()}

                {/* Discount */}
                {generateDiscountHTML()}
              </View>
            )}

            {/* Total */}
            {generateFinalPriceHtml()}
          </View>

          {/* Side Note: Expiration Date */}
          <View
            style={[
              {
                marginTop: "8px",
              },
            ]}
          >
            <Text
              style={[
                {
                  fontSize: "12px",
                },
              ]}
            >
              El precio propuesto estará vigente hasta {generateExpiryDate()}.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default QuotePage;
