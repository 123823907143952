import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { formatter } from "../../helpers/helper";
import { getProductPrice } from "../helpers/helper";
// import { hasSolarPanels } from "../helpers/helper";
// import text from "./lang";
// import moment from "moment";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 20,
    margin: "8 0",
    fontFamily: "Helvetica-Bold",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  greenText: {
    color: "#198754",
  },

  redText: {
    color: "#CC0000",
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "5",
    paddingBottom: "3",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: "1 solid #ccc",
    width: "33.333333%",
    height: "100%",
    fontSize: "9",
  },
  headerCell: {
    backgroundColor: "#2C4596",
    color: "#FFFFFF",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function PaymentPlanPage(props) {
  const { /*lang,*/ quote } = props;

 

  const generateDiscount = () => {
    if (quote.discount && parseFloat(quote.discount) > 0) {
      return parseFloat(quote.discount) / 100;
    }

    return 0;
  };

  const generateProductsPrice = () => {
    if (quote.quote_products) {
      let result = quote.quote_products.reduce((sumPrice, qp) => {
        return (sumPrice +=
          getProductPrice(qp.product, qp.quantity, quote.quote_products) *
          parseInt(qp.quantity, 10));
      }, 0);

      return result / 100;
    }

    return 0;
  };

  const generateFinalPrice = () => {
    let finalPrice = 0;
    if (quote.quote_products) {
      finalPrice += generateProductsPrice() - generateDiscount();
    }

    if (quote.allowance && quote.allowance > 0) {
      finalPrice += quote.allowance / 100;
    }

    return finalPrice;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <View>
            {/*Payment Plan*/}
            <View
              style={[
                {
                  fontSize: "24px",
                  //   marginBottom: 24,
                  textAlign: "left",
                },
                styles.bold,
                styles.upperCase,
                styles.grayText,
              ]}
            >
              <Text>Plan de pago</Text>
            </View>

            {/* 30, 50 20 % */}
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  textAlign: "left",
                  width: "100%",
                },
              ]}
            >
              {/* 20% */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    width: "28%",
                    margin: "16 0",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.secondaryHeader,
                    styles.upperCase,
                    { marginBottom: 8, color: "#43A7DF" },
                  ]}
                >
                  20%
                </Text>
                <Text
                  style={[
                    styles.orangeText,
                    { fontSize: "16", marginBottom: 8 },
                  ]}
                >
                  ({formatter.format(generateFinalPrice() * 0.2)})
                </Text>
                <Text style={[{ fontSize: "12" }]}>
                  Al firmar el acuerdo de servicio, se comienza el proceso de
                  diseño y se asigna “site visit”.
                </Text>
              </View>

              {/* 50% */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    width: "28%",
                    margin: "16 0",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.secondaryHeader,
                    styles.upperCase,
                    { marginBottom: 8, color: "#43A7DF" },
                  ]}
                >
                  50%
                </Text>
                <Text
                  style={[
                    styles.orangeText,
                    { fontSize: "16", marginBottom: 8 },
                  ]}
                >
                  ({formatter.format(generateFinalPrice() * 0.5)})
                </Text>
                <Text style={[{ fontSize: "12" }]}>
                  Previamente a la instalación.
                </Text>
              </View>

              {/* 30% */}
              <View
                style={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    margin: "16 0",
                    width: "28%",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.secondaryHeader,
                    styles.upperCase,
                    { marginBottom: 8, color: "#43A7DF" },
                  ]}
                >
                  30%
                </Text>
                <Text
                  style={[
                    styles.orangeText,
                    { fontSize: "16", marginBottom: 8 },
                  ]}
                >
                  ({formatter.format(generateFinalPrice() * 0.3)})
                </Text>
                <Text style={[{ fontSize: "12" }]}>
                  Al finalizar la instalación y el sistema esté en
                  funcionamiento.
                </Text>
              </View>
            </View>
            {/* Side note */}
            <View>
              <Text style={[{ fontSize: "12" }]}>
                *Este último pago no esta sujeto a que PREPA (LUMA) culmine su
                responsabilidad del proceso de Medición Neta.*
              </Text>
            </View>

            {quote.comments && quote.comments.length > 0 && (
              <>
                {/* Comments Section Title */}
                <View
                  style={[
                    {
                      fontSize: "24px",
                      marginBottom: 24,
                      textAlign: "left",
                      marginTop: "24px",
                    },
                    styles.bold,
                    styles.upperCase,
                    styles.grayText,
                  ]}
                >
                  <Text>Comentarios adicionales</Text>
                </View>
                <Text
                  style={[
                    {
                      fontSize: "12px",
                    },
                  ]}
                >
                  {quote.comments}
                </Text>
              </>
            )}
          </View>

          {/* Side Note */}
          <View>
            <Text
              style={[
                {
                  fontSize: "12px",
                  paddingBottom: "8px",
                },
                styles.orangeText,
                styles.bold,
              ]}
            >
              Nota Aclaratoria
            </Text>
            <Text
              style={[
                {
                  fontSize: "12px",
                },
              ]}
            >
              Power Solar LLC asistirá en el proceso de interconexión con PREPA
              en acuerdo con las regulaciones de PREPA y el Gobierno de Puerto
              Rico para el cumplimiento de Act 114/211. No obstante Power Solar
              LLC, no puede garantizar un tiempo determinado para dicha gestion
              ya que puede estar sujeto a factores adicionales de cumplimiento
              del Proyecto propuesto.
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

export default PaymentPlanPage;
