import React, { useEffect, useCallback, useState } from "react";
import { Form, Input, InputNumber, Select, Space } from "antd";
import categories from "../../../api/data/categories";
import { fetchSubcategoriesAction } from "../../../redux/actions/subcategoryActions";
import { MinusCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Button } from "../../buttons/buttons";

const { Option } = Select;
function ProductForm(props) {
  const {
    handleSubmit,
    id,
    name,
    category,
    subcategory,
    description,
    english_description,
    price,
    datasheet_name,
    product_prices,
    kw,
    form,
    subcategories,
    fetchSubcategories,
  } = props;

  const initialValues = {
    id,
    product_prices: product_prices
      ? product_prices.map((p) => ({ ...p, price: p.price / 100 }))
      : [],
  };

  const fetchSubcategoriesCallback = useCallback(() => {
    if (subcategories && subcategories.length === 0) fetchSubcategories();
  }, [subcategories, fetchSubcategories]);

  const [showMultiplePrices, setShowMultiplePrices] = useState(false);

  const generateCategoryOptions = () => {
    return categories.map((c, i) => (
      <Option key={i} value={c}>
        {c}
      </Option>
    ));
  };

  const generateSubcategoryOptions = () => {
    return subcategories.filter(c => c.is_enabled).map((c) => (
      <Option key={c.id} value={c.name}>
        {c.name}
      </Option>
    ));
  };

  const onCategoryChange = (value) => {
    if (value === "Solar Panel" || value === "Battery")
      setShowMultiplePrices(true);
    else setShowMultiplePrices(false);
  };

  useEffect(() => {
    if (category === "Solar Panel" || category === "Battery")
      setShowMultiplePrices(true);
    else setShowMultiplePrices(false);
  }, [category]);

  useEffect(() => {
    fetchSubcategoriesCallback();
  }, [fetchSubcategoriesCallback]);

  return (
    <Form
      name="editProduct"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {id && <p>ID: {id}</p>}
      <Form.Item
        name="name"
        rules={[{ message: "Please input product name!", required: true }]}
        initialValue={name ? name : ""}
        label="Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="category"
        rules={[{ message: "Please input product category!", required: true }]}
        initialValue={category ? category : ""}
        label="Category"
      >
        <Select style={{ minWidth: "300px" }} onChange={onCategoryChange}>
          <Option value="">Categories</Option>
          {generateCategoryOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        name="subcategory"
        rules={[
          { message: "Please input product sub category!", required: true },
        ]}
        initialValue={subcategory ? subcategory : ""}
        label="Subcategory"
      >
        <Select style={{ minWidth: "300px" }} onChange={onCategoryChange}>
          <Option value="">Subcategories</Option>
          {generateSubcategoryOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        name="description"
        rules={[
          {
            message: "Please input spanish product description!",
            required: true,
          },
        ]}
        initialValue={description ? description : ""}
        label="Spanish Description"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="english_description"
        rules={[
          {
            message: "Please input english product description!",
            required: true,
          },
        ]}
        initialValue={english_description ? english_description : ""}
        label="English Description"
      >
        <Input />
      </Form.Item>

      {showMultiplePrices && (
        <>
          <p>Product Prices</p>

          <Form.List name="product_prices">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                      alignItems: "center",
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "range_start"]}
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: "Range start is required",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Range start" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "range_end"]}
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: "Range end is required",
                        },
                      ]}
                    >
                      <InputNumber placeholder="Range end" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "price"]}
                      rules={[
                        {
                          required: true,
                          message: "Price is required",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <InputNumber placeholder="price" addonAfter="$" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    onClick={() => add()}
                    shape="round"
                    type="primary"
                    size="default"
                    block
                  >
                    <FeatherIcon icon="plus" size={18} /> Add
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item
            name="kw"
            initialValue={kw ? kw : ""}
            label="kw"
            rules={[{ message: "Please input product kw!", required: true }]}
          >
            <Input />
          </Form.Item>
        </>
      )}

      {!showMultiplePrices && (
        <Form.Item
          name="price"
          initialValue={price ? price / 100 : ""}
          label="price"
          rules={[{ message: "Please input product price!", required: true }]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item
        name="datasheet_name"
        initialValue={datasheet_name ? datasheet_name : ""}
        label="Datasheet Name"
        rules={[
          { message: "Please input product datasheet name!", required: true },
        ]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item>
                <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                {loading ? 'Loading...' : 'Sign In'}
                </Button>
            </Form.Item> */}
    </Form>
  );
}

let mapStateToProps = (state) => ({
  subcategories: state.subcategories.subcategories,
  loading: state.subcategories.loading,
});

let mapDispatchToProps = (dispatch) => ({
  fetchSubcategories: () => dispatch(fetchSubcategoriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
