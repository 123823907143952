import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import text from "./lang";

const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    fontSize: 14,
    padding: 0,
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  // },
  header: {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    backgroundColor: "#1D4091",
    color: "#fff",
    padding: "16 16 8 16",
    marginBottom: 16,
    borderBottom: "solid 3px #0E9CD5",
  },
  headerText: {
    fontSize: 14,
    color: "#1f497d",
    margin: "0 10",
  },
  bodyText: {
    fontSize: "12px",
    color: "#343a40",
    margin: "0",
    lineHeight: "2px",
  },
  secondaryHeader: {
    fontSize: 16,
    margin: "8 0",
  },
  flex: {
    display: "flex",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
  },
  justifiedRight: {
    textAlign: "right",
  },
  orangeText: {
    color: "#E9682A",
  },
  grayText: {
    color: "#343a40",
  },

  blueText: {
    color: "#2C4596",
  },

  lightGrayBackground: {
    backgroundColor: "#F2F2F2",
  },
  table: {
    width: "100%",
    borderRight: "1px solid #FFF",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  cell: {
    paddingTop: "12",
    paddingBottom: "10",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "33.333333%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell100: {
    paddingTop: "12",
    paddingBottom: "10",
    paddingLeft: "8",
    paddingRight: "8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderLeft: "1px solid #E6E6E6",
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
    height: "100%",
    fontSize: "10",
    backgroundColor: "#E6E6E6",
  },
  cell15: {
    padding: "4px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "15%",
    fontSize: "10",
    lineHeight: 1.4,
  },
  headerCell: {
    backgroundColor: "#E9682A",
    color: "#FFFFFF",
    border: "1px solid #E9682A",
    fontFamily: "Helvetica-Bold",
  },
  totalPrice: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  lineText: {
    display: "flex",
    flexDirection: "row",
  },
});

function ProductionsPage(props) {
  const { quote /*, lang */ } = props;

  const generateProductions = () => {
    let productions = [];

    // Get the sum of all watts in the products in the quote
    let wattsTotal = quote.quote_products.reduce((sumkw, qp) => {
      return (sumkw += qp.product.kw
        ? parseInt(qp.product.kw, 10) * parseInt(qp.quantity, 10)
        : 0);
    }, 0);

    // Apply production generated formula
    wattsTotal = Math.round(wattsTotal * 1.6)

    // Loop over 25 years
    for (let i = 0; i < 25; i++) {
      productions.push({ year: i + 1, production: wattsTotal });

      // Apply production generated formula, it tears down year over year
      wattsTotal = Math.round(wattsTotal * 0.993);
    }

    return productions.map((s) => (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: s.year % 2 === 0 ? "#F2F2F2" : "#FFFFFF",
          width: "50%",
        }}
      >
        <Text
          style={[styles.cell15, { width: "50%", textAlign: "center" }]}
        >
          {s.year}
        </Text>
        <Text
           style={[
            styles.cell15,
            {
              width: "50%",
              textAlign: "center",
            },
          ]}
        >
          {s.production.toLocaleString()}kWh
        </Text>
      </View>
    ));
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Page Container */}
      <View
        style={{
          width: "100%",
          display: "flex",
          // border: "1px solid #ccc",
          boxSizing: "border-box",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {/* Header */}
        <View
          style={[
            {
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#1D4091",
              color: "#fff",
              padding: "20 16 20 16",
              borderBottom: "4px solid #0E9CD5",
              marginBottom: 0,
              fontSize: 14,
              height: "72px",
            },
            styles.upperCase,
          ]}
        >
          {/* Page title */}
          {/* <Text>Cotización Comercial</Text> */}
          <Image
            style={{
              height: "28px",
            }}
            src={`/assets/commercial quotes images/powersolar-logo-white.png`}
          />
        </View>

        {/* Page Content */}
        <View
          style={{
            padding: 48,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center"
          }}
        >
          {/* Page Title */}
          <View
            style={[
              {
                fontSize: "24px",
                marginBottom: 24,
                textAlign: "center",
                textWrap: "nowrap",
              },
              styles.bold,
              styles.upperCase,
              styles.grayText,
            ]}
          >
            <Text>Producción Energética - 25 años </Text>
            <Text>(estimados)</Text>
          </View>

          <View
            style={[
              {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",}
            ]}
          >
            <Text
               style={[
                styles.cell15,
                styles.headerCell,
                {
                  width: "25%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "5px 20px 4px 20px",
                },
              ]}
            >
            
              Año
            </Text>
            <Text
               style={[
                styles.cell15,
                styles.headerCell,
                {
                  width: "25%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "5px 20px 4px 20px",
                },
              ]}
            >
            
              Producción
            </Text>
          </View>
          {generateProductions()}
        </View>
      </View>
    </Page>
  );
}

export default ProductionsPage;
