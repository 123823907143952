import React, { useState, useLayoutEffect } from "react";
import { Form } from "antd";
import { WizardWrapper } from "../../../containers/Quotations/Create/Style";
import { Steps } from "../../steps/steps";
import CustomerForm from "./steps/CustomerForm";
import FinanceMethodForm from "./steps/FinanceMethodForm";
import SelectProductForm from "./steps/SelectProductForm";
import QuoteReview from "./steps/QuoteReview";
import SuccessStep from "./steps/SuccessStep";
import { createQuoteAction } from "../../../redux/actions/quoteActions";
import { connect } from "react-redux";

const QuotationForm = (props) => {
  const { createQuote } = props;
  const [form] = Form.useForm();

  const [state, setState] = useState({
    status: "process",
    validation: false,
    isFinished: false,
    current: 0,
    quote: {},
  });

  const { status, isFinished, current, validation } = state;

  useLayoutEffect(() => {
    const activeElement = document.querySelectorAll(".ant-steps-item-active");
    const successElement = document.querySelectorAll(".ant-steps-item-finish");

    activeElement.forEach((element) => {
      if (element.previousSibling) {
        const bgImage = element.previousSibling;
        if (bgImage.classList.contains("success-step-item")) {
          bgImage.classList.remove("success-step-item");
        } else {
          bgImage.classList.remove("wizard-step-item");
        }
        bgImage.classList.add("wizard-steps-item-active");
      }
    });

    successElement.forEach((element) => {
      if (element.previousSibling) {
        const bgImage = element.previousSibling;
        bgImage.classList.remove("wizard-steps-item-active");
        bgImage.classList.add("success-step-item");
      }
    });
  });

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setState({
          ...state,
          status: "process",
          validation: true,
          current: current + 1
        });
      })
      .catch(() => {
        setState({
          ...state,
          validation: false,
        });
      });
  };
  const validationStatus = () => {
    setState({
      ...state,
      validation: false,
    });
  };
  const prev = () => {
    setState({
      ...state,
      status: "process",
      current: current - 1,
    });
  };

  const done = () => {
    let formProducts = [];
    if (form.getFieldValue("solarPanels")) {
      formProducts = [...formProducts, ...form.getFieldValue("solarPanels")];
    }
    if (form.getFieldValue("inverters")) {
      formProducts = [...formProducts, ...form.getFieldValue("inverters")];
    }
    if (form.getFieldValue("batteries")) {
      formProducts = [...formProducts, ...form.getFieldValue("batteries")];
    }
    createQuote({
      ...form.getFieldsValue(true),
      luma_monthly_bill: parseFloat(form.getFieldValue("luma_monthly_bill")) * 100,
      discount: parseFloat(form.getFieldValue("discount")) * 100,
      products: formProducts,
    }).then((result) => {
      if (result && result.quote && result.quote.id) {
        setState({
          ...state,
          status: "finish",
          isFinished: true,
          current: 3,
          quote: result.quote,
        });
      }
    });
  };

  const handleResetForm = () => {
    setState({...state, current: 0, status: "process", isFinished: false})
    form.resetFields()
  }

  return (
    <WizardWrapper>
      <Steps
        isswitch
        current={state.current}
        status={status}
        validation={validation}
        validationStatus={validationStatus}
        steps={[
          {
            title: "Customer Information",
            content: <CustomerForm form={form} />,
          },
          {
            title: "Finance Method",
            content: <FinanceMethodForm form={form} />,
          },
          {
            title: "Add Products",
            content: <SelectProductForm form={form} />,
          },
          {
            title: "Review Quotation",
            content:
              status !== "finish" ? (
                <QuoteReview form={form} setStep={(step) => setState({...state, current: step, status: "process", isFinished: false})} />
              ) : (
                <SuccessStep form={form} quote={state.quote} resetForm={handleResetForm} />
              ),
          },
        ]}
        onNext={next}
        onPrev={prev}
        onDone={done}
        isfinished={isFinished}
      />
    </WizardWrapper>
  );
};

let mapDispatchToProps = (dispatch) => ({
  createQuote: (formValues) => dispatch(createQuoteAction(formValues)),
});

export default connect(null, mapDispatchToProps)(QuotationForm);
