import React, { useEffect } from "react";
import { Row, Col, Input, Select, Form } from "antd";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import categories from "../../api/data/categories";

const { Option } = Select;
const FilterRow = (props) => {
  const { setFilteredProducts, products, form } = props;

  const handleFilter = () => {
    let filteredProducts = [...products];

    if (form.getFieldValue("name")) {
      filteredProducts = filteredProducts.filter((p) =>
        p.name.toLowerCase().includes(form.getFieldValue("name").toLowerCase())
      );
    }

    if (form.getFieldValue("category")) {
      filteredProducts = filteredProducts.filter(
        (p) => p.category === form.getFieldValue("category")
      );
    }

    if (form.getFieldValue("is_enabled") !== "") {
      filteredProducts = filteredProducts.filter(
        (p) => p.is_enabled === form.getFieldValue("is_enabled")
      );
    }

    setFilteredProducts([...filteredProducts]);
  };

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line
  }, [products]);

  const handleClear = () => {
    setFilteredProducts([...products]);
    form.resetFields();
    handleFilter();
  };

  const generateCategoryOptions = () => {
    return categories.map((c, i) => (
      <Option key={i} value={c}>
        {c}
      </Option>
    ));
  };

  return (
    <Form
      name="products-filter"
      form={form}
      style={{ paddingBottom: "15px", borderBottom: "1px solid #d6d6d6" }}
      initialValues={{ is_enabled: true }}
    >
      <Row gutter={30}>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="name">
            <Input placeholder="Name" />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="category">
            <Select style={{ minWidth: "300px" }} value={""}>
              <Option value="">Categories</Option>
              {generateCategoryOptions()}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={8} xs={24} className="mb-25">
          <Form.Item name="is_enabled">
            <Select style={{ minWidth: "300px" }}>
              <Option value={true}>Enabled</Option>
              <Option value="">All</Option>
              <Option value={false}>Disabled</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row></Row>
      <Row>
        <Col sm={12} xs={24}>
          <div className="sDash_form-action">
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              onClick={() => handleFilter()}
            >
              <FeatherIcon icon="search" size={14} />
              Apply
            </Button>
            <Button type="light" onClick={handleClear}>
              <FeatherIcon icon="x" size={14} />
              Clear
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterRow;
