import React from "react";
import { Route } from "react-router-dom";
import UsersContainer from "../Users/UsersContainer";

const AdminRolesRoutes = [
  <Route path="/app/users" element={<UsersContainer />} />,
  // <Route path="/app/promo-codes" element={<PromoCodesContainer />} />,
];

export default AdminRolesRoutes;
