import React, { useEffect, useCallback } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Row, Col, Form, Skeleton } from "antd";
import { connect } from "react-redux";
import { fetchProductsAction } from "../../../../redux/actions/productActions";
import { fetchSubcategoriesAction } from "../../../../redux/actions/subcategoryActions";
import FormProductFieldList from "../../../inputs/FormProductFieldList";

function SelectProductForm(props) {
  const {
    form,
    products,
    fetchProducts,
    subcategories,
    fetchSubcategories,
    loading,
  } = props;

  const fetchProductsCallback = useCallback(() => {
    if (products && products.length === 0) fetchProducts();
  }, [products, fetchProducts]);

  const fetchSubcategoriesCallback = useCallback(() => {
    if (subcategories && subcategories.length === 0) fetchSubcategories();
  }, [subcategories, fetchSubcategories]);

  useEffect(() => {
    fetchProductsCallback();
    fetchSubcategoriesCallback();
  }, [fetchProductsCallback, fetchSubcategoriesCallback]);

  const getSubcategoryProducts = (category, subcategory) => {
    let subcategoryProducts = [];
    if (products && products.length > 0) {
      subcategoryProducts = category
        ? (subcategoryProducts = [
            ...products.filter(
              (p) =>
                p.category === category &&
                p.is_enabled &&
                p.subcategory === subcategory
            ),
          ])
        : [...products];
    }

    return subcategoryProducts;
  };

  const generateProductSubcategoryOptions = (products) => {
    if (products.length > 0) {
      return products.map((p) => ({
        label: p.name,
        value: p.id,
      }));
    }
  };

  const generateProductOptions = (category) => {
    let productOptions = [];
    if (products && products.length > 0) {
      productOptions = subcategories.filter(c => c.is_enabled).map((s, i) => {
        let products = [...getSubcategoryProducts(category, s.name)];

        if (products.length > 0) {
          return {
            label: <span>{s.name}</span>, // Label for the group
            title: s.name, // Title for the group (optional, can be the same as label)
            options: generateProductSubcategoryOptions(products), // Nested options
          };
        }
        return null;
      });

      // Filter out null values (subcategories with no products)
      return productOptions.filter((o) => o !== null);
    }

    return productOptions;
  };

  const isFieldName = (nameArray, value1, value2, value3) => {
    if (nameArray.length < 3) {
      return false;
    }
    if (
      nameArray[0] === value1 &&
      Number.isInteger(value2) &&
      nameArray[2] === value3
    )
      return true;

    return false;
  };

  const handleChange = (changed, allFields) => {
    // If the field changed is an inverter id
    if (
      changed.length > 0 &&
      changed[0].name.length === 3 &&
      isFieldName(changed[0].name, "inverters", changed[0].name[1], "id")
    ) {
      // If the inverter changed has a matching solar panel in the same index with a quantity value
      if (
        allFields[0].value &&
        allFields[0].value[changed[0].name[1]] &&
        allFields[0].value[changed[0].name[1]].quantity
      ) {
        const fields = form.getFieldsValue();
        const { inverters } = fields;
        // If the inverter has a length greater than the index changed
        if (inverters && inverters.length > changed[0].name[1]) {
          let newInverters = [...inverters];
          // Update inverter quantity to the quantity in the solar panel with the same index
          newInverters[changed[0].name[1]].quantity =
            allFields[0].value[changed[0].name[1]].quantity;
          // Update
          form.setFieldsValue({ ...newInverters });
        }
      }
    }
  };

  return (
    <BasicFormWrapper className="basic-form-inner">
      {loading && (
        <Col sm={24} xs={24}>
          <Skeleton paragraph={{ rows: 20 }} active />
        </Col>
      )}
      {!loading && (
        <div className="atbd-form-checkout">
          <Row justify="center">
            <Col sm={22} xs={24}>
              <div className="payment-method-form">
                <Heading as="h4">3. Please Add Your Products</Heading>
                <div className="shipping-selection">
                  <Form
                    form={form}
                    name="products"
                    onFieldsChange={handleChange}
                  >
                    <Heading as="h5">Solar Panels</Heading>
                    <FormProductFieldList
                      name="solarPanels"
                      products={generateProductOptions("Solar Panel")}
                    />
                    <Heading as="h5">Inverter</Heading>
                    <FormProductFieldList
                      name="inverters"
                      products={generateProductOptions("Inverter")}
                    />
                    <Heading as="h5">Batteries</Heading>
                    <FormProductFieldList
                      name="batteries"
                      products={generateProductOptions("Battery")}
                    />
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  products: state.products.products,
  subcategories: state.subcategories.subcategories,
  loading: state.products.loading || state.subcategories.loading,
});

let mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(fetchProductsAction()),
  fetchSubcategories: () => dispatch(fetchSubcategoriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectProductForm);
