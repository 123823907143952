import React from "react";
import { Modal } from "./antd-modals";
import { connect } from "react-redux";
import { updateSubcategoryAction } from "../../redux/actions/subcategoryActions";

function EnableSubcategoryModal(props) {
  const { subcategory, open, setOpen, updateSubcategory } = props;

  const handleSubmit = () => {
    if (subcategory && subcategory.id) {
      updateSubcategory(subcategory.id, { is_enabled: !subcategory.is_enabled }).then(
        (result) => {
          if (result && result.id) {
            setOpen(false);
          }
        }
      );
    }
  };

  return (
    <Modal
      title={
        subcategory && subcategory.is_enabled ? "Disable Subcategory" : "Enable Subcategory"
      }
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <p>
        Are you sure you wish to {subcategory && subcategory.is_enabled ? "Disable" : "Enable"}{" "}
        this subcategory?
      </p>
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  updateSubcategory: (id, formData) => dispatch(updateSubcategoryAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(EnableSubcategoryModal);
