import React, { useEffect } from "react";
import { Row, Col, Skeleton } from "antd";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import QuotationsList from "./../Quotations/List/QuotationsList";
import { connect } from "react-redux";
import { fetchQuotesAction } from "../../redux/actions/quoteActions";
import { fetchNewEnergy2QuotesAction } from "../../redux/actions/newEnergy2QuoteActions";
import { fetchCommercialCommercialQuotesAction } from "../../redux/actions/commercialQuoteActions";
import { setCurrentKeyAction } from "../../redux/actions/uiActions";
import { adminRoles } from "../../api/data/roles";
import "./dashboard.css";
import moment from "moment";

const Dashboard = (props) => {
  const {
    quotes,
    fetchQuotes,
    fetchCommercialCommercialQuotes,
    newEnergy2Quotes,
    fetchNewEnergy2Quotes,
    loading,
    setCurrentKey,
    currentUser,
  } = props;

  useEffect(() => {
    if (!quotes || (quotes && quotes.length === 0)) {
      fetchQuotes();
      if (adminRoles.includes(currentUser.role)) {
        fetchCommercialCommercialQuotes();
      }
    }
    if (
      !newEnergy2Quotes ||
      (newEnergy2Quotes && newEnergy2Quotes.length === 0)
    ) {
      fetchNewEnergy2Quotes();
    }

    // eslint-disable-next-line
  }, []);

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  const getAllQuotes = () => {
    return [...quotes];
  };

  const getMyQuotes = () => {
    return [...quotes.filter((q) => q.user_id === currentUser.id)];
  };

  const getMyNewEnergy2Quotes = () => {
    return [...newEnergy2Quotes.filter((q) => q.user_id === currentUser.id)];
  };

  return (
    <>
      <Main>
        <Row className="dashboard-row" gutter={[16, 24]}>
          {loading && (
            <Col sm={24} xs={24}>
              <Cards headless>
                <Skeleton paragraph={{ rows: 20 }} active />
              </Cards>
            </Col>
          )}
          {!loading && (
            <>
              {/* Create Quotes Shortcuts */}
              <Col style={{ marginTop: "24px" }}>
                <h1>{`¡Bienvenido, ${currentUser.name}!`}</h1>
                {/* <PageHeader title={`¡Bienvenido, ${currentUser.name}!`} /> */}
              </Col>

              <Col span={24}>
                <h3 className="dashboard-subtitles">Ventas Regulares</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]}>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="ecoflow"
                    size="large"
                    onClick={() =>
                      setCurrentKey("/app/eco-flow-quotations/new")
                    }
                  >
                    <FeatherIcon icon="sun" size={14} />
                    Nueva Cotización de EcoFlow
                  </Button>
                </Col>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="primary"
                    size="large"
                    onClick={() => setCurrentKey("/app/quotations/new")}
                  >
                    <FeatherIcon icon="home" size={14} />
                    Nueva Cotización Residencial
                  </Button>
                </Col>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="gray"
                    size="large"
                    onClick={() => setCurrentKey("/app/roof-quotations/new")}
                  >
                    <FeatherIcon icon="sun" size={14} />
                    Nueva Cotización de Sellado de Techo
                  </Button>
                </Col>
              </Row>

              <Col span={24}>
                <h3 className="dashboard-subtitles">Ventas Comerciales</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]}>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="primary"
                    size="large"
                    onClick={() =>
                      setCurrentKey("/app/commercial-quotations/new")
                    }
                  >
                    <FeatherIcon icon="sun" size={14} />
                    Nueva Cotización Comercial
                  </Button>
                </Col>
              </Row>

              {/* Create Estimates Shortcuts */}

              <Col span={24}>
                <h3 className="dashboard-subtitles">Ventas Vivienda</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]}>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="secondary"
                    size="large"
                    onClick={() =>
                      setCurrentKey("/app/vivienda-estimates-energy-2/new")
                    }
                    outlined
                  >
                    <FeatherIcon icon="plus" size={16} />
                    Estimado Nueva Energía 2
                  </Button>
                </Col>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="secondary"
                    size="large"
                    onClick={() => setCurrentKey("/app/vivienda-estimates/new")}
                    outlined
                  >
                    <FeatherIcon icon="plus" size={16} />
                    Nuevo Estimado Vivienda
                  </Button>
                </Col>
              </Row>

              {/* Create Estimates Shortcuts */}

              <Col span={24}>
                <h3 className="dashboard-subtitles">Promociones</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]}>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="warning"
                    size="large"
                    onClick={() => handleClick("https://forms.office.com/r/UZPrcfMc7f")}
                  >
                    <FeatherIcon icon="plus" size={16} />
                    Bono Reclutamiento
                  </Button>
                </Col>
                <Col xl={6} lg={8} sm={12} xs={24}>
                  <Button
                    className="shortcut-button"
                    type="warning"
                    size="large"
                    onClick={() => handleClick("https://forms.office.com/r/mLZKjdM2iW")}
                  >
                    <FeatherIcon icon="plus" size={16} />
                    Ruleta Acompañante
                  </Button>
                </Col>
              </Row>

              {/* Stats */}

              <Col span={24}>
                <h3 className="dashboard-subtitles">Stats</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]}>
                {currentUser && adminRoles.includes(currentUser.role) && (
                  <>
                    <Col md={6} sm={12} xs={24}>
                      <div className="stats-card">
                        <p className="stats-title">Total Quotes</p>
                        <p className="stats-number">{getAllQuotes().length}</p>
                      </div>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <div className="stats-card">
                        <p className="stats-title">This Month (All Quotes)</p>
                        <p className="stats-number">
                          {
                            getAllQuotes().filter((q) =>
                              moment(q.created_at).isSame(moment(), "month")
                            ).length
                          }
                        </p>
                      </div>
                    </Col>
                  </>
                )}

                <Col md={6} sm={12} xs={24}>
                  <div className="stats-card">
                    <p className="stats-title">My Quotes</p>
                    <p className="stats-number">{getMyQuotes().length}</p>
                  </div>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <div className="stats-card">
                    <p className="stats-title">This Month (My Quotes)</p>
                    <p className="stats-number">
                      {
                        getMyQuotes().filter((q) =>
                          moment(q.created_at).isSame(moment(), "month")
                        ).length
                      }
                    </p>
                  </div>
                </Col>
              </Row>

              {/* New Energy 2 Quotes */}
              <Col span={24}>
                <h3 className="dashboard-subtitles">Stats Nueva Energía 2</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]}>
                {currentUser && adminRoles.includes(currentUser.role) && (
                  <>
                    <Col md={6} sm={12} xs={24}>
                      <div className="stats-card">
                        <p className="stats-title">Total Quotes</p>
                        <p className="stats-number">
                          {newEnergy2Quotes.length}
                        </p>
                      </div>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <div className="stats-card">
                        <p className="stats-title">Today</p>
                        <p className="stats-number">
                          {
                            newEnergy2Quotes.filter((q) =>
                              moment(q.created_at).isSame(moment(), "day")
                            ).length
                          }
                        </p>
                      </div>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <div className="stats-card">
                        <p className="stats-title">This Week</p>
                        <p className="stats-number">
                          {" "}
                          {
                            newEnergy2Quotes.filter((q) =>
                              moment(q.created_at).isSame(moment(), "week")
                            ).length
                          }
                        </p>
                      </div>
                    </Col>
                  </>
                )}

                <Col md={6} sm={12} xs={24}>
                  <div className="stats-card">
                    <p className="stats-title">My Quotes</p>
                    <p className="stats-number">
                      {getMyNewEnergy2Quotes().length}
                    </p>
                  </div>
                </Col>
              </Row>

              <Col span={24}>
                <h3 className="dashboard-subtitles">Recent Quotes</h3>
              </Col>

              <Row className="dashboard-row" gutter={[16, 12]} justify="center">
                {/* Recent quotes  */}
                <Col xs={24}>
                  <QuotationsList
                    quotes={getMyQuotes()
                      .sort(
                        (a, b) => moment(b.created_at) - moment(a.created_at)
                      )
                      .slice(0, 4)}
                    showCount={false}
                    showPagination={false}
                    gutter={[16, 12]}
                  />
                </Col>

                {/* View all button */}
                <Col md={8} sm={24} xs={24}>
                  <Button
                    className="shortcut-button"
                    // type="default"
                    size="large"
                    onClick={() => setCurrentKey("/app/quotations")}
                  >
                    <FeatherIcon icon="grid" size={14} />
                    View All Quotes
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Row>
      </Main>
    </>
  );
};

let mapStateToProps = (state) => ({
  quotes: state.quotes.quotes,
  newEnergy2Quotes: state.newEnergy2Quotes.newEnergy2Quotes,
  loading: state.quotes.loading && state.newEnergy2Quotes.loading,
  currentUser: state.auth.currentUser,
});

let mapDispatchToProps = (dispatch) => ({
  fetchQuotes: () => dispatch(fetchQuotesAction()),
  setCurrentKey: (key) => dispatch(setCurrentKeyAction(key)),
  fetchCommercialCommercialQuotes: () =>
    dispatch(fetchCommercialCommercialQuotesAction()),
  fetchNewEnergy2Quotes: () => dispatch(fetchNewEnergy2QuotesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
