import React from "react";
import { Form, Input } from "antd";

function SubcategoryForm(props) {
  const { handleSubmit, id, name, form } = props;

  return (
    <Form
      name="editSubcategory"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      {id && <p>ID: {id}</p>}
      <Form.Item
        name="name"
        rules={[{ message: "Name is required!", required: true }]}
        initialValue={name ? name : ""}
        label="Name"
      >
        <Input />
      </Form.Item>
      {/* <Form.Item>
                <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                {loading ? 'Loading...' : 'Sign In'}
                </Button>
            </Form.Item> */}
    </Form>
  );
}

export default SubcategoryForm;
