import React from "react";
import QuotationPDF from "../QuotationPDF/QuotationPDF";
import { pdf } from "@react-pdf/renderer";
import PDFMerger from "pdf-merger-js";
import { saveAs } from "file-saver";
import CommercialQuotationPDF from "../CommercialQuotationPDF/CommercialQuotationPDF";
import EcoFlowPDF from "../EcoFlowPDF/EcoFlowPDF";
import RoofQuotationPDF from "../RoofQuotationPDF/RoofQuotationPDF";
import ViviendaEstimatePDF from "../ViviendaEstimatePDF/ViviendaEstimatePDF";
import SunnovaIncentivePDF from "../SunnovaIncentivePDF/SunnovaIncentivePDF";
import { ViviendaEstimatePDF as NuevaEnergiaEstimatePDF } from "../ViviendaEstimateNuevaEnergia2PDF/ViviendaEstimatePDF";
import { ecoFlowCategories } from "../../api/data/categories";

export function getProductBasePrice(product, quantity) {
  if (!(product.category === "Solar Panel" || product.category === "Battery"))
    return product.price;

  let price = 0;

  for (let i = 0; i < product.product_prices.length; i++) {
    if (
      quantity >= product.product_prices[i].range_start &&
      quantity <= product.product_prices[i].range_end
    ) {
      price = product.product_prices[i].price;
    }
  }

  if (product.category === "Solar Panel") {
    return price * parseFloat(product.kw, 10);
  }

  return price;
}

export function getProductPrice(
  product,
  quantity,
  products = [],
  financeMethod,
  approvedPercentage = 0
) {
  if (ecoFlowCategories.includes(product.category)) {
    let price = product.price;
    if (ecoFlowCategories.includes(product.category)) {
      if (financeMethod && financeMethod === "Synchrony") {
        price *= 1.06;
      } else if (financeMethod && financeMethod === "United") {
        price *= 1 + (100 - approvedPercentage) / 100;
      }
    }

    return price;
  }

  if (!(product.category === "Solar Panel" || product.category === "Battery"))
    return product.price;

  // Add special steel frame cost
  if (
    product.category === "Battery" &&
    (product.name.toLowerCase().includes("steel frame") ||
      product.name.toLowerCase().includes("carport"))
  ) {
    let watts = 0;

    if (products && products.length > 0) {
      watts = products.reduce((sum, p) => {
        if (p.product.category === "Solar Panel")
          return sum + parseFloat(p.product.kw) * 100 * p.quantity;
        return sum;
      }, 0);
    }

    return watts * 2.5;
  }

  let price = 0;

  for (let i = 0; i < product.product_prices.length; i++) {
    if (
      quantity >= product.product_prices[i].range_start &&
      quantity <= product.product_prices[i].range_end
    ) {
      price = product.product_prices[i].price;
    }
  }

  if (product.category === "Solar Panel") {
    // Add 20 cents per watt for synchrony payment method
    if (financeMethod && financeMethod === "Synchrony") {
      return (
        price * parseFloat(product.kw, 10) + 20 * parseFloat(product.kw, 10)
      );
    }
    return price * parseFloat(product.kw, 10);
  }

  return price;
}

export function countSolarPanels(quote) {
  if (hasSolarPanels(quote)) {
    return quote.quote_products.reduce((sum, qp) => {
      if (qp.product.category === "Solar Panel") return sum + qp.quantity;
      return sum;
    }, 0);
  }
  return 0;
}

export function countInverters(quote) {
  if (hasSolarPanels(quote)) {
    return quote.quote_products.reduce((sum, qp) => {
      if (qp.product.category === "Inverter") return sum + qp.quantity;
      return sum;
    }, 0);
  }
  return 0;
}

export function getSolarPanel(quote) {
  if (hasSolarPanels(quote)) {
    for (let i = 0; i < quote.quote_products.length; i++) {
      if (quote.quote_products[i].product.category === "Solar Panel") {
        return quote.quote_products[i].product;
      }
    }
  }
  return {};
}

export function getInverter(quote) {
  if (hasSolarPanels(quote)) {
    for (let i = 0; i < quote.quote_products.length; i++) {
      if (quote.quote_products[i].product.category === "Inverter") {
        return quote.quote_products[i].product;
      }
    }
  }
  return {};
}

// Returns true if the quote has a solar panel product, otherwise returns false
export function hasSolarPanels(quote) {
  return (
    quote &&
    quote.quote_products &&
    quote.quote_products.some((qp) => qp.product.category === "Solar Panel")
  );
}

// Returns true if the quote has a product with the text 'vivienda' on it, otherwise returns false
export function hasVivienda(quote) {
  return (
    quote &&
    quote.quote_products &&
    quote.quote_products.some(
      (qp) => qp.product && qp.product.name.toLowerCase().includes("vivienda")
    )
  );
}

// Returns true if the quote has an inverter product with the name PW+
export function hasTeslaInverter(quote) {
  return (
    quote &&
    quote.quote_products &&
    quote.quote_products.some(
      (qp) =>
        qp.product &&
        qp.product.name.toLowerCase().includes("pw+") &&
        qp.product.category.toLowerCase().includes("inverter")
    )
  );
}

// Returns true if the quote has a solar panel product with the name 455
export function hasPanel455(quote) {
  return (
    quote &&
    quote.quote_products &&
    quote.quote_products.some(
      (qp) =>
        qp.product &&
        qp.product.name.toLowerCase().includes("455") &&
        qp.product.category.toLowerCase().includes("solar panel")
    )
  );
}

// Returns an array of all the files in a given context
// Example
// r = require.context('../../../../assets/datasheets', false, /\.(pdf)$/)
export function importPdfs(r) {
  let files = {};

  if (r && r.keys()) {
    r.keys().forEach((key) => (files[key] = r(key)));

    return Object.keys(files).map((key) => files[key]);
  }

  return [];
}

// Generate quote pdf
export async function generateQuotePDF(quote, lang, type = "residential") {
  let doc = "";
  if (type === "residential") doc = <QuotationPDF quote={quote} lang={lang} />;
  else if (type === "roof")
    doc = <RoofQuotationPDF quote={quote} lang={lang} />;
  else if (type === "ecoFlow") doc = <EcoFlowPDF quote={quote} lang={lang} />;
  else {
    doc = <CommercialQuotationPDF quote={quote} lang={lang} />;
  }

  // Only add contract if type is residential, commercial should not show contracts.
  let addContract = type === "residential";

  let asPdf = pdf([]); // {} is important, throws without an argument
  asPdf.updateContainer(doc);

  let appendixBlob = await asPdf.toBlob();

  let merger = new PDFMerger();

  await merger.add(appendixBlob);

  let pdfs = importPdfs(
    require.context("../../assets/datasheets", false, /\.(pdf)$/)
  );

  if (type === "residential" || type === "commercial" || type === "ecoFlow") {
    let sortedQuoteProducts = quote.quote_products.sort((a, b) => {
      if (
        a.product.category === "Solar Panel" ||
        b.product.category === "Battery"
      )
        return -1;
      if (
        b.product.category === "Solar Panel" ||
        a.product.category === "Battery"
      )
        return 1;
      return 0;
    });

    for (let i = 0; i < sortedQuoteProducts.length; i++) {
      let qp = sortedQuoteProducts[i];
      if (qp && qp.product && qp.product.datasheet_name) {
        // Eliminate contract for quotes with government assistance
        if (
          addContract &&
          qp.product &&
          qp.product.name &&
          qp.product.name.toLowerCase().includes("vivienda")
        )
          addContract = false;

        let index = pdfs
          .map((pdf) => {
            let fileLocation = pdf.split("/");
            let fileSplit = fileLocation[fileLocation.length - 1].split(".");
            return fileSplit[0];
          })
          .indexOf(qp.product.datasheet_name.toLowerCase());
        if (index >= 0) {
          await merger.add(pdfs[index]);
        }
      }
    }

    if (hasSolarPanels(quote)) {
      let index = pdfs
        .map((pdf) => {
          let fileLocation = pdf.split("/");
          let fileSplit = fileLocation[fileLocation.length - 1].split(".");
          return fileSplit[0];
        })
        .indexOf("unirac");

      await merger.add(pdfs[index]);
    }
  }

  if (addContract) {
    let index = pdfs
      .map((pdf) => {
        let fileLocation = pdf.split("/");
        let fileSplit = fileLocation[fileLocation.length - 1].split(".");
        return fileSplit[0];
      })
      .indexOf("contratoborrador" + lang);

    await merger.add(pdfs[index]);
  }

  // Add silicone pdfs to roof quotes with silicone product
  if (
    type === "roof" &&
    quote.sealing_type &&
    quote.sealing_type.toLowerCase().includes("silicone")
  ) {
    let roofPdfs = importPdfs(
      require.context("../../assets/roof pdfs", false, /\.(pdf)$/)
    );

    let index = roofPdfs
      .map((pdf) => {
        let fileLocation = pdf.split("/");
        let fileSplit = fileLocation[fileLocation.length - 1].split(".");
        return fileSplit[0];
      })
      .indexOf("silicone-roof-contract");

    await merger.add(roofPdfs[index]);

    index = roofPdfs
      .map((pdf) => {
        let fileLocation = pdf.split("/");
        let fileSplit = fileLocation[fileLocation.length - 1].split(".");
        return fileSplit[0];
      })
      .indexOf("silicone-guide");

    await merger.add(roofPdfs[index]);

    index = roofPdfs
      .map((pdf) => {
        let fileLocation = pdf.split("/");
        let fileSplit = fileLocation[fileLocation.length - 1].split(".");
        return fileSplit[0];
      })
      .indexOf("silicone-maintenance");

    await merger.add(roofPdfs[index]);
  }

  const mergedPdf = await merger.saveAsBlob();

  saveAs(mergedPdf, "Cotizacion.pdf");
}

export async function generateViviendaEstimatePDF(values, products, user) {
  let doc = (
    <ViviendaEstimatePDF values={values} products={products} user={user} />
  );

  let asPdf = pdf([]); // {} is important, throws without an argument
  asPdf.updateContainer(doc);

  let appendixBlob = await asPdf.toBlob();

  let merger = new PDFMerger();

  await merger.add(appendixBlob);

  const mergedPdf = await merger.saveAsBlob();

  saveAs(mergedPdf, "Estimado Para Vivienda.pdf");
}

export async function generateSunnovaIncentivePDF(values, products, user) {
  let doc = (
    <SunnovaIncentivePDF values={values} products={products} user={user} />
  );

  let asPdf = pdf([]); // {} is important, throws without an argument
  asPdf.updateContainer(doc);

  let appendixBlob = await asPdf.toBlob();

  let merger = new PDFMerger();

  await merger.add(appendixBlob);

  const mergedPdf = await merger.saveAsBlob();

  saveAs(mergedPdf, "Sunnova Lease - Incentivo.pdf");
}

export async function generateViviendaEstimateNuevaEnergia2PDF(values, user) {
  let doc = <NuevaEnergiaEstimatePDF values={values} user={user} />;

  let asPdf = pdf([]); // {} is important, throws without an argument
  asPdf.updateContainer(doc);

  let appendixBlob = await asPdf.toBlob();

  let merger = new PDFMerger();

  let pdfs = importPdfs(
    require.context("../../assets/datasheets", false, /\.(pdf)$/)
  );

  // Add initial PDF
  let index = pdfs
    .map((pdf) => {
      let fileLocation = pdf.split("/");
      let fileSplit = fileLocation[fileLocation.length - 1].split(".");
      return fileSplit[0];
    })
    .indexOf("nueva-energia-pt1");

  await merger.add(pdfs[index]);

  // Add generated PDF
  await merger.add(appendixBlob);

  let pdfsToAddAfter = [];

  if (values.product === "Paneles solares y baterías") {
    pdfsToAddAfter = [
      "nueva-energia-pt2",
      "canadian400",
      "enphaseiq8h",
      "teslapw2",
      "unirac",
    ];
  } else if (values.product === "Paneles solares y baterías +") {
    pdfsToAddAfter = ["nueva-energia-pt2", "canadian400", "teslapw+", "unirac"];
  } else if (values.product === "Baterías") {
    pdfsToAddAfter = ["nueva-energia-pt2", "teslapw2"];
  }

  // Add the rest of the pdfs
  for (let i = 0; i < pdfsToAddAfter.length; i++) {
    index = pdfs
      .map((pdf) => {
        let fileLocation = pdf.split("/");
        let fileSplit = fileLocation[fileLocation.length - 1].split(".");
        return fileSplit[0];
      })
      .indexOf(pdfsToAddAfter[i]);

    await merger.add(pdfs[index]);
  }

  // Save all as one PDF
  const mergedPdf = await merger.saveAsBlob();

  // Return merged PDF
  saveAs(mergedPdf, "Estimado Para Vivienda.pdf");
}

export function calculateSquareFootCost(squareFoot, sealing_type, membrane) {
  let cost = 0;

  if (sealing_type === "Silicone") {
    cost = 6.5;
  } else if (sealing_type === "Mantenimiento") {
    cost = 1.75;
  } else {
    if (parseInt(membrane, 10) === 1) {
      cost = 5.5;
    } else if (parseInt(membrane) === 2) {
      cost = 7.13;
    }
  }

  return Math.round(cost * parseInt(squareFoot, 10) * 100) / 100;
}

export function calculateRemoveSealingCost(squareFoot, membrane) {
  let cost = 0;

  if (parseInt(membrane, 10) === 1) {
    cost = 1;
  } else if (parseInt(membrane, 10) === 2) {
    cost = 2;
  }

  return (cost * parseInt(squareFoot, 10)).toFixed(2);
}

export function calculateRoofQuoteCost(quote) {
  let totalCost = 0;

  // Add square foot cost
  totalCost += parseFloat(
    calculateSquareFootCost(quote["square_foot"], quote["sealing_type"], 1)
  );

  // Add parapet cost
  if (quote.has_parapet) {
    totalCost += quote.parapet_foot * 3;
  }

  // Add puddle cost
  if (quote.has_puddle) {
    totalCost += quote.puddle_square_foot * 3;
  }

  // Add roof sealing removal cost
  if (quote.remove_roof_sealing) {
    totalCost += parseFloat(
      calculateRemoveSealingCost(quote["existing_roof_sealing_square_foot"], 1)
    );
  }

  // Add roof objects removal cost
  if (quote.roof_quote_objects && quote.roof_quote_objects.length > 0) {
    totalCost += quote.roof_quote_objects.reduce((accumulator, item) => {
      return (
        accumulator + parseFloat(item.quantity) * (item.roof_object.cost / 100)
      );
    }, 0);
  }

  return totalCost;
}

export function generateDiscountPrice(quote) {
  if (quote.quote_promo_codes && quote.quote_promo_codes.length > 0) {
    let result = quote.quote_promo_codes.reduce((sumDiscount, p) => {
      return (sumDiscount += parseInt(p.promo_code.discount, 10));
    }, 0);

    return result / 100;
  }

  return 0;
}
