import { API_URL } from "../constants/apiURLConstants";
import {
  LOADING_SUBCATEGORIES,
  CLEAR_LOADING_SUBCATEGORIES,
  FETCH_SUBCATEGORIES,
  CREATE_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
} from "../constants/subcategoryConstants";
import axios from "axios";
import { errorHandler } from "../helpers.js/helper";

export function fetchSubcategoriesAction() {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBCATEGORIES });
    return (
      axios
        .get(`${API_URL}subcategories`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data) {
            dispatch({ type: FETCH_SUBCATEGORIES, payload: responseJSON.data });
            return responseJSON.data;
          }
          dispatch({ type: CLEAR_LOADING_SUBCATEGORIES });
          return {};
        })
        .catch((error) => {
          errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_SUBCATEGORIES });
          });
        })
    );
  };
}

export function createSubcategoryAction(formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBCATEGORIES });
    return (
      axios
        .post(`${API_URL}subcategories`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.subcategory) {
            dispatch({
              type: CREATE_SUBCATEGORY,
              payload: responseJSON.data.subcategory,
            });
            return responseJSON.data.subcategory;
          }
          dispatch({ type: CLEAR_LOADING_SUBCATEGORIES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_SUBCATEGORIES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}

export function updateSubcategoryAction(subcategoryId, formData) {
  return (dispatch) => {
    dispatch({ type: LOADING_SUBCATEGORIES });
    return (
      axios
        .patch(`${API_URL}subcategories/${subcategoryId}`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        // eslint-disable-next-line consistent-return
        .then((responseJSON) => {
          if (responseJSON && responseJSON.data && responseJSON.data.subcategory) {
            dispatch({
              type: UPDATE_SUBCATEGORY,
              payload: responseJSON.data.subcategory,
            });
            return responseJSON.data.subcategory;
          }
          dispatch({ type: CLEAR_LOADING_SUBCATEGORIES });
          return responseJSON;
        })
        .catch((error) => {
          return errorHandler(dispatch, error, () => {
            dispatch({ type: CLEAR_LOADING_SUBCATEGORIES });
            if (
              error.response &&
              error.response.data &&
              error.response.data.errors &&
              Object.keys(error.response.data.errors).length > 0
            ) {
              return error.response.data.errors;
            }
            return false;
          });
        })
    );
  };
}
