import React, { useEffect } from "react";
import { Modal } from "./antd-modals";
import SubcategoryForm from "../forms/SubcategoryForm/SubcategoryForm";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  createSubcategoryAction,
  updateSubcategoryAction,
} from "../../redux/actions/subcategoryActions";

function SubcategoryFormModal(props) {
  const { subcategory, open, setOpen, updateSubcategory, createSubcategory } = props;

  const [form] = Form.useForm();

  const handleSubmit = () => {
    let data = form.getFieldsValue(true);
    if (subcategory && subcategory.id) {
      updateSubcategory(subcategory.id, data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    } else {
      createSubcategory(data).then((result) => {
        if (result && result.id) {
          setOpen(false);
        }
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [subcategory, form]);

  return (
    <Modal
      title={subcategory && subcategory.id ? "Edit Subcategory" : "Create Subcategory"}
      visible={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      type="primary"
      footer={false}
    >
      <SubcategoryForm {...subcategory} form={form} />
    </Modal>
  );
}

let mapDispatchToProps = (dispatch) => ({
  createSubcategory: (formData) => dispatch(createSubcategoryAction(formData)),
  updateSubcategory: (id, formData) => dispatch(updateSubcategoryAction(id, formData)),
});

export default connect(null, mapDispatchToProps)(SubcategoryFormModal);
