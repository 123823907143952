import React from "react";
import { Document } from "@react-pdf/renderer";
import CoverPage from "./CoverPage";
import AgreementPageTwo from "./AgreementPageTwo";
import AgreementCover from "./AgreementCover";
import AgreementPageOne from "./AgreementPageOne";
import AgreementPageFour from "./AgreementPageFour";
import AgreementPageThree from "./AgreementPageThree";

function RoofQuotationPDF(props) {
  const { quote, lang } = props;
  return (
    <Document>
      <CoverPage quote={quote} lang={lang} />
      {quote.sealing_type &&
        quote.sealing_type.toLowerCase().includes("danosa") && (
          <>
            <AgreementCover lang={lang} quote={quote} />
            <AgreementPageOne lang={lang} quote={quote} />
            <AgreementPageTwo quote={quote} lang={lang} />
            <AgreementPageThree quote={quote} lang={lang} />
            <AgreementPageFour quote={quote} lang={lang} />
          </>
        )}
    </Document>
  );
}

RoofQuotationPDF.defaultProps = {
  lang: "en",
};

export default RoofQuotationPDF;
