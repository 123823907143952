import React from "react";
import { Route } from "react-router-dom";
import ProductsContainer from "../Products/ProductsContainer";
import SubcategoriesContainer from "../Subcategories/SubcategoriesContainer";

const AdminRoutes = [
  <Route path="/app/products" element={<ProductsContainer />} />,
  <Route path="/app/subcategories" element={<SubcategoriesContainer />} />,
];

export default AdminRoutes;
