import React from "react";
import FeatherIcon from "feather-icons-react";
import { Row } from "antd";
import { UserCard } from "./style";
import Heading from "../heading/heading";
import { Cards } from "../cards/frame/cards-frame";
import { Button } from "../buttons/buttons";

const SubcategoryCard = (props) => {
  const {
    name,
    is_enabled,
    setOpenModal,
    setOpenEnableModal,
  } = props;

  return (
    <UserCard>
      <div className="card user-card">
        <Cards headless>
          <div className="card__content">
            <Heading className="card__name, body-text-large" as="h3">
              {name}
            </Heading>
          </div>

          <div className="card__actions">
            <Button
              size="default"
              type="white"
              onClick={() => setOpenModal({ ...props })}
            >
              <FeatherIcon icon="edit-3" size={14} />
              Edit
            </Button>
            <Button
              size="default"
              type="white"
              onClick={() => setOpenEnableModal({ ...props })}
            >
              <FeatherIcon icon="x" size={14} />
              {is_enabled ? "Disable" : "Enable"}
            </Button>
          </div>
          <div className="card-footer">
            <Row justify="center" style={{ flexDirection: "column" }}>
              {is_enabled && (
                <p className="text-success" style={{ fontWeight: "bold" }}>
                  Enabled
                </p>
              )}
              {!is_enabled && (
                <p className="text-danger" style={{ fontWeight: "bold" }}>
                  Disabled
                </p>
              )}
              <p>Status</p>
            </Row>
          </div>
        </Cards>
      </div>
    </UserCard>
  );
};

export default SubcategoryCard;
