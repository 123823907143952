import {
  LOADING_SUBCATEGORIES,
  CLEAR_LOADING_SUBCATEGORIES,
  FETCH_SUBCATEGORIES,
  CREATE_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
} from "../constants/subcategoryConstants";

const initialState = {
  subcategories: [],
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_LOADING_SUBCATEGORIES:
      return {
        ...state,
        loading: false,
      };
    case LOADING_SUBCATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBCATEGORIES:
      return {
        ...state,
        loading: false,
        subcategories: action.payload,
      };
    case CREATE_SUBCATEGORY:
      return {
        ...state,
        loading: false,
        subcategories: [...state.subcategories, action.payload],
      };
    case UPDATE_SUBCATEGORY:
      return {
        ...state,
        loading: false,
        subcategories: [
          ...state.subcategories.filter((p) => p.id !== action.payload.id),
          action.payload,
        ],
      };
    default:
      return state;
  }
}
