import { configureStore } from "@reduxjs/toolkit";
import auth from "./modules/auth";
import products from "./modules/products";
import warranties from "./modules/warranties";
import quotes from "./modules/quotes";
import users from "./modules/users";
import ui from "./modules/ui";
import promoCodes from "./modules/promoCodes";
import roofObjects from './modules/roofObjects'
import newEnergy2Quotes from './modules/newEnergy2Quotes'
import subcategories from './modules/subcategories'

const store = configureStore({
  reducer: {
    auth,
    products,
    warranties,
    quotes,
    users,
    ui,
    promoCodes,
    roofObjects,
    newEnergy2Quotes,
    subcategories,
    // search,
    // users,
    // campaigns,
    // batches,
  },
});

export default store;
