import React from "react";
import { Button } from "../buttons/buttons";
import { Form, Input, Select, Space } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

import FeatherIcon from "feather-icons-react";

function FormProductFieldList(props) {
  const { name, products } = props;

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: "flex",
                marginBottom: 8,
                width: "100%",
              }}
              align="center"
            >
              <Form.Item
                {...restField}
                name={[name, "id"]}
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Product is required",
                  },
                ]}
                style={{ width: "100%", minWidth: "100%" }}
              >
                <Select
                  style={{ minWidth: "220px", width: "100%" }}
                  showSearch
                  notFoundContent={null}
                  options={products}
                  placeholder="Products"
                  optionFilterProp="label"
                ></Select>
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "quantity"]}
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
                style={{ maxWidth: "60px" }}
              >
                <Input
                  placeholder="0"
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    margin: "0px",
                  }}
                />
              </Form.Item>
              <MinusCircleOutlined
                className="text-danger"
                onClick={() => remove(name)}
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              onClick={() => add()}
              shape="round"
              type="primary"
              size="default"
              block
            >
              <FeatherIcon icon="plus" size={18} /> Add
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default FormProductFieldList;
