import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton, Form } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import FeatherIcon from "feather-icons-react";
import FilterRow from "./FilterRow";
import ProductsList from "./ProductsList";
import { connect } from "react-redux";
import { fetchProductsAction } from "../../redux/actions/productActions";
import ProductFormModal from "../../components/modals/ProductFormModal";
import EnableProductModal from "../../components/modals/EnableProductModal";

const ProductsContainer = (props) => {
  const { products, fetchProducts, loading } = props;

  useEffect(() => {
    if (!products || (products && products.length === 0)) {
      fetchProducts();
    }
    // eslint-disable-next-line
  }, []);

  const [filteredProducts, setFilteredProducts] = useState(
    products ? [...products] : []
  );

  const [form] = Form.useForm();

  const [openModal, setOpenModal] = useState(false);
  const [openEnableModal, setOpenEnableModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleClick = () => {
    setCurrentProduct({});
    setOpenModal(true);
  };

  return (
    <>
      <PageHeader
        title="Products List"
        buttons={[
          <div key="6" className="page-header-actions">
            <Button size="small" type="primary" onClick={handleClick}>
              <FeatherIcon icon="plus" size={14} />
              Add New
            </Button>
          </div>,
        ]}
      />
      <Main>
        <Row gutter={25}>
          {loading && (
            <Col sm={24} xs={24}>
              <Cards headless>
                <Skeleton paragraph={{ rows: 20 }} active />
              </Cards>
            </Col>
          )}
          {!loading && (
            <>
              <Col sm={24} xs={24}>
                <FilterRow
                  setFilteredProducts={setFilteredProducts}
                  products={products}
                  form={form}
                />
              </Col>
              <Col sm={24} xs={24}>
                <ProductsList
                  products={filteredProducts ? [...filteredProducts] : []}
                  setOpen={setOpenModal}
                  setOpenEnableModal={setOpenEnableModal}
                  setCurrentProduct={setCurrentProduct}
                />
              </Col>
            </>
          )}
        </Row>
        <ProductFormModal
          product={currentProduct}
          open={openModal}
          setOpen={setOpenModal}
        />
        <EnableProductModal
          product={currentProduct}
          open={openEnableModal}
          setOpen={setOpenEnableModal}
        />
      </Main>
    </>
  );
};

let mapStateToProps = (state) => ({
  products: state.products.products,
  loading: state.products.loading,
});

let mapDispatchToProps = (dispatch) => ({
  fetchProducts: () => dispatch(fetchProductsAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
